/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:60b977c1-1e9f-4a98-b06e-65278fddf30e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jNeAVx6ND",
    "aws_user_pools_web_client_id": "78ioe50265rt0l431ld0bn4pfc",
    "oauth": {
        "domain": "sso-sanktionsliste-neumann.auth.eu-central-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://b2cakqzx6jcvxffimoz5hxjgiy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fab35jh2njbc3llefwqtix4qgq",
    "aws_cloud_logic_custom": [
        {
            "name": "loginConfigApi",
            "endpoint": "https://d80kdixxuc.execute-api.eu-central-1.amazonaws.com/neumann",
            "region": "eu-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "quotas-neumann",
            "region": "eu-central-1"
        },
        {
            "tableName": "CustomerSettings-neumann",
            "region": "eu-central-1"
        },
        {
            "tableName": "SanktionslisteSettingsTable",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
